
import Vue from 'vue'
import {searchSpaceList} from '@/api/common'
export default Vue.extend({
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    placeholder: {
      type: String,
      default: '楼栋-单元-[楼层]房间',
    },
    popperAppendToBody: {
      type: Boolean,
      default: true,
    },
    optionList: {
      type: Array<any>,
      default: () => [],
    },
  },
  data() {
    return {
      list: [] as Array<any>,
      loading: false,
    }
  },
  computed: {
    item: {
      set(val) {
        this.$emit('input', val === '' ? {} : val)
      },
      get() {
        return this.value
      },
    },
  },
  watch: {
    optionList(cur) {
      if (cur && cur.length) {
        this.list = this.optionList
      }
    },
  },
  methods: {
    formatSpace(item: any) {
      if (item.room_number) {
        return `${item.building_number}-${item.building_unit_number}-${item.room_number}`
      }
      return `${item.building_name}${item.building_unit_id ? item.building_unit_name : ''}${item.building_floor_id ? item.building_floor_name : ''}`
    },
    async filter(keyword: string) {
      if (keyword !== '') {
        this.loading = true
        const data = await searchSpaceList({keyword})
        this.list = Object.keys(data).length ? data : []
        this.loading = false
      } else {
        this.list = []
      }
    },
    select(id: any) {
      const item = this.list.find(e => e.id === id)
      this.$emit('change', id, item)
    },
    focus(e: FocusEvent) {
      !(e.target as HTMLInputElement).value && (this.list = [])
    },
  },
})
