
import Vue from 'vue'
import {dpListContract} from '@/api/company/contract'
export default Vue.extend({
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    placeholder: {
      type: String,
      default: '合同名称、编号',
    },
  },
  data() {
    return {
      list: [],
      loading: false,
    }
  },
  computed: {
    item: {
      set(val) {
        this.$emit('input', val)
      },
      get() {
        return this.value
      },
    },
  },
  methods: {
    async filter(keyword: string) {
      if (keyword !== '') {
        this.loading = true
        const data = await dpListContract({keyword})
        this.list = Object.keys(data).length ? data : []
        this.loading = false
      } else {
        this.list = []
      }
    },
    select(id: any) {
      const item = this.list.find((e: any) => e.id === id)
      this.$emit('change', id, item)
    },
  },
})
