/*
 * @Date         : 2023-02-08 18:06:11
 * @Author       : dwyanewang
 * @LastEditors  : dwyanewang
 * @LastEditTime : 2023-09-28 11:19:16
 * @description  : 登录状态管理
 * @FilePath     : \yf-groupadmin_v2\src\store\modules\login.ts
 */

import {getUserInfo, logout, getMenuList, login, loginByCode} from '@/api/login'
import {CurUser, LoginState} from '@/types/user'
import {delCookie, setCookie} from '@/utils/dw'
import {RouteConfig} from 'vue-router'
import _ from 'lodash'

import {Commit, Module} from 'vuex'
import {resetRouter} from '@/router'

export default {
  namespaced: true,
  state: {
    currentUser: {
      id: undefined,
      name: '',
      avatar: '',
      perms: [],
      menus: [],
    },
    asyncRoutes: [],
    companyList: [],
  },
  mutations: {
    SAVE_CURRENT_USER(state, payload) {
      state.currentUser = payload
    },
    SAVE_ASYNCROUTES(state, payload) {
      state.asyncRoutes = payload
    },
    SAVE_COMPANYLIST(state, payload) {
      state.companyList = payload
    },
  },
  actions: {
    // 登录
    async Login(store, payload) {
      try {
        let data: any
        if (!payload.code) {
          // 账户登录
          data = await login(payload.username, payload.password)
        } else {
          // 验证码登录
          data = await loginByCode(payload.mobile, payload.code)
        }
        setCookie({
          name: 'token',
          value: data.token || '',
          options: {expires: data?.expire ? new Date(data.expire) : new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)},
        })
        return true
      } catch (error) {
        return false
      }
    },
    // 获取当前用户
    async GetUserInfo({commit}) {
      try {
        const user = await getUserInfo()
        const menus = await getMenuList()
        commit(
          'SAVE_CURRENT_USER',
          {id: user.id, name: user.nick_name, avatar: user.face, menus, companyId: user.switch_company_id || (user.my_company_list[0]?.id ?? 0)} || {},
        )
        commit('SAVE_COMPANYLIST', user.my_company_list || [])
        return true
      } catch (error) {
        return false
      }
    },
    GenerateRoutes({commit}, menus: CurUser['menus']) {
      const routes = transformAsyncRoutes(menus)
      const _defaultRoutes = _.cloneDeep(routes)
      commit('SAVE_ASYNCROUTES', routes)
      _defaultRoutes.push({
        path: '/:pathMatch(.*)*',
        redirect: '/404',
      })
      return _defaultRoutes
    },
    FrontLogOut({commit}) {
      clearLogin(commit)
      return true
    },
    // 登出
    async LogOut({commit}) {
      try {
        await logout()
        clearLogin(commit)
        return true
      } catch (error) {
        return false
      }
    },
  },
} as Module<LoginState, LoginState>

const LAYOUT = () => import('@/layout/index.vue')
const BlankLayout = () => import('@/layout/BlankLayout.vue')

function transformAsyncRoutes(routes: CurUser['menus']) {
  return routes.map(route => {
    const tmp = {...route} as any
    if (route.children) {
      tmp.children = transformAsyncRoutes(route.children)
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Layout') {
        tmp!.component = LAYOUT
      } else if (route.component === 'BlankLayout') {
        tmp!.component = BlankLayout
      } else {
        tmp!.component = loadView(route.component)
      }
    }
    if (!route.meta.icon) {
      tmp.meta.icon = 'system'
    }
    return tmp as RouteConfig
  })
}

const loadView = (view: string) => {
  if (process.env.NODE_ENV === 'development') {
    return (resolve: any) => require([`@/views/${view}.vue`], resolve)
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return () => import(`@/views/${view}.vue`)
  }
}

const clearLogin = (commit: Commit) => {
  delCookie(['token', 'refreshToken'])
  commit('SAVE_CURRENT_USER', {})
  commit('SAVE_ASYNCROUTES', [])
  resetRouter()
}
