
import Vue from 'vue'
import {searchRoomList} from '@/api/common'
export default Vue.extend({
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '请输入房间名',
    },
    size: {
      type: String,
      default: 'small',
    },
    optionList: {
      type: Array<any>,
      default: () => [],
    },
  },
  data() {
    return {
      list: [] as Array<any>,
      loading: false,
    }
  },
  computed: {
    item: {
      set(val) {
        this.$emit('input', val)
      },
      get() {
        return this.value
      },
    },
  },
  created() {
    this.optionList.length && (this.list = this.optionList)
  },
  methods: {
    async filter(keyword: string) {
      if (keyword !== '') {
        this.loading = true
        const data = await searchRoomList({keyword, ...this.params})
        this.list = Object.keys(data).length
          ? data.map((e: any) => ({
              id: e.id,
              name: `${e.building_number}-${e.building_unit_number}-${e.room_number}`,
              label: `${e.room_name}【${e.building_number}-${e.building_unit_number}-${e.room_number}】`,
            }))
          : []
        this.loading = false
      } else {
        this.list = []
      }
    },
    select(id: any) {
      const item = this.list.find(e => e.id === id)
      this.$emit('change', id, item)
    },
    focus(e: FocusEvent) {
      !(e.target as HTMLInputElement).value && (this.list = [])
    },
  },
})
