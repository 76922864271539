
import Vue from 'vue'
import {mapGetters} from 'vuex'
import {dpListAdminPhone} from '@/api/common'
export default Vue.extend({
  props: {
    value: {
      type: [Number, String, Array<number | string>],
      default: '',
    },
    placeholder: {
      type: String,
      default: '用户昵称、手机号',
    },
    optionList: {
      type: Array<any>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    reserveKeyword: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      list: [] as Array<any>,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({company: 'company'}),
    item: {
      set(val) {
        this.$emit('input', val)
      },
      get() {
        return this.value
      },
    },
  },
  created() {
    this.optionList.length && (this.list = this.optionList)
  },
  methods: {
    async filter(keyword: string) {
      if (keyword !== '') {
        this.loading = true
        const data = await dpListAdminPhone(keyword, 1, this.company.id)
        this.list = Object.keys(data).length ? data : []
        this.loading = false
      } else {
        this.list = []
      }
    },
    select(id: number) {
      const item = this.list.find(e => e.id === id)
      this.$emit('change', id, item)
    },
    focus(e: FocusEvent) {
      !(e.target as HTMLInputElement).value && (this.list = [])
    },
  },
})
