
import Vue from 'vue'
export default Vue.extend({
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    order(): any {
      return this.info
    },
    types(): string {
      const _typeObj = {0: 'warning', 10: 'danger', 20: '', 30: 'info', 40: 'success'}
      return _typeObj[this.info.status as keyof typeof _typeObj]
    },
  },
})
