/*
 * @Date         : 2022-03-19 10:18:24
 * @Author       : dwyanewang
 * @LastEditors  : dwyanewang
 * @LastEditTime : 2023-04-13 14:13:36
 * @description  :
 * @FilePath     : \yf-groupadmin_v2\src\store\getters.ts
 */
import {LoginState} from '@/types/user'
import {RouteConfig} from 'vue-router'
import {GetterTree} from 'vuex'

type State = {
  login: LoginState
}

export default {
  menus: (state: State): any[] => state.login.currentUser.menus,
  perms: (state: State): string[] => state.login.currentUser.perms,
  asyncRoutes: (state: State): Array<RouteConfig> => state.login.asyncRoutes,
  homeRoute: (state: State): string | undefined => {
    const _asyncRoutes: Array<RouteConfig> = state.login.asyncRoutes
    if (_asyncRoutes.length > 0) {
      return _asyncRoutes[0].name
    }
    return undefined
  },
  company: (state: State): any => state.login.companyList.find(e => e.id === state.login.currentUser.companyId),
} as GetterTree<State, State>
