/*
 * @Date         : 2023-04-13 17:47:50
 * @Author       : dwyanewang
 * @LastEditors  : dwyanewang
 * @LastEditTime : 2023-05-24 16:58:00
 * @description  : 节流
 * @FilePath     : \yf-groupadmin_v2\src\directive\throttle.ts
 */
import {DirectiveBinding} from 'vue'

/***
 *  节流 每单位时间可触发一次
 *  第一次瞬间触发，最后一次不管是否达到间隔时间依然触发
 * 【考虑到input的change事件】
 *  @param {?Number|300} time - 间隔时间
 *  @param {Function} fn - 执行事件
 *  @param {?String|"click"} event - 事件类型 例："click"
 *  @param {Array} binding.value - [fn,event,time]
 *  例：<el-button v-throttle="[reset,`click`,300]">刷新</el-button>
 *  传递参数则：<el-button v-throttle="[()=>reset(param),`click`,300]">刷新</el-button>
 */
export default {
  inserted(el: HTMLElement, binding: DirectiveBinding<any>) {
    const [fn, event = 'click', time = 300] = binding.value
    let timer: number | null, timer_end: number | undefined
    el.addEventListener(event, () => {
      if (timer) {
        window.clearTimeout(timer_end)
        return (timer_end = window.setTimeout(() => fn(), time))
      }
      fn()
      timer = window.setTimeout(() => (timer = null), time)
    })
  },
}
