
import Vue from 'vue'
export default Vue.extend({
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    type: String,
  },
  computed: {
    hasType() {
      return ['pt_company_account_deduction'].includes(this.type)
    },
  },
})
