import { render, staticRenderFns } from "./account-records.vue?vue&type=template&id=ccfa7214&scoped=true&"
import script from "./account-records.vue?vue&type=script&lang=ts&"
export * from "./account-records.vue?vue&type=script&lang=ts&"
import style0 from "./account-records.vue?vue&type=style&index=0&id=ccfa7214&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccfa7214",
  null
  
)

export default component.exports