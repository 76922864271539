
import {ElForm} from 'element-ui/types/form'
import Vue from 'vue'
export default Vue.extend({
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    isInit: {
      type: Boolean,
      default: false,
    },
    isTree: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: String,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    treeProps: {
      type: Object,
      default: () => ({
        hasChildren: 'hasChildren',
        children: 'children',
      }),
    },
  },
  data() {
    return {
      loading: false,
      tableData: [] as Array<any>,
      page: 1,
      pageSize: 10,
      total: 0,
    }
  },
  created() {
    this.$nextTick(() => this.formDomResize())
    this.isInit && this.onSearch({all2one: true})
  },
  mounted() {
    window.onresize = () => this.formDomResize()
  },
  methods: {
    async onSearch({flag = false, all2one = false, type}: {flag?: boolean; all2one?: boolean; type?: string} = {}) {
      this.loading = true
      if (this.isTree) {
        this.$emit(
          'tree-search',
          (_tableData: Array<any>) => {
            this.tableData = _tableData
            this.loading = false
          },
          all2one,
        )
      } else {
        if (type === 'del') {
          this.tableData.length === 1 && this.page--
          this.total === 1 && (this.page = 1)
        }
        flag && (this.page = 1)
        this.$emit(
          'search',
          {page: this.page, pageSize: this.pageSize},
          (total: number, tableData: Array<any>) => {
            this.total = total
            this.tableData = tableData
            this.loading = false
          },
          all2one,
        )
      }
    },
    extraReset() {
      this.$emit('extra-reset')
    },
    selectChanged(selection: any[]) {
      this.$emit('select-rows', selection)
    },
    sortChange({property, order}: {property: any; order: any}) {
      this.$emit('table-sort', {property, order})
    },
    mergeCell({rowIndex, columnIndex}: {rowIndex: number; columnIndex: number}) {
      let _span = {rowspan: 1, colspan: 1}
      this.$emit('merge-cell', {rowIndex, columnIndex}, (e: {rowspan: number; colspan: number}) => {
        _span = e
      })
      return _span
    },
    getSummaries({columns, data}: {columns: any; data: any}) {
      let sums: any[] = []
      this.$emit('summary-method', {columns, data}, (e: any[]) => {
        sums = e
      })
      return sums
    },
    handleSizeChange() {
      this.page = 1
      this.onSearch()
    },
    formDomResize() {
      const _el = (this.$refs['form'] as ElForm)?.$el
      if (_el) {
        const _diff = _el.clientHeight - 64
        _el.classList.remove('line-1', 'line-2', 'line-3', 'line-4', 'line-5')
        _el.classList.add(`line-${Math.floor(_diff / 48) + 1}`)
      }
    },
  },
})
