
import Vue from 'vue'
export default Vue.extend({
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      hz: {visible: false, info: {}},
    }
  },
  computed: {
    order(): any {
      return (f => ({
        sn: f.sn,
        refundway_text: f.refundway_text,
        add_time: this.$parseTime(f.add_time),
        refund_amount: f.refund_amount,
        refund_time: this.$parseTime(f.refund_time),
        refund_remarks: f.refund_remarks,
        refund_reason: f.refund_reason,
        status: f.status,
        status_text: f.status_text,
        pay_orders_id: f.pay_orders_id,
        action_title: f.action_title,
        pay_orders_sn: f.pay_orders_sn,
        payway: f.payway_text,
        paytype: f.paytype,
        account_name_text: f.account_name_text,
        orders_info: f.orders_info,
      }))(this.info.data)
    },
    logs_json(): any {
      return this.info.data.logs_json.filter((ele: any) => /[\u4E00-\u9FA5\uF900-\uFA2D]{1,}/.test(ele.desc))
    },
    extra(): any {
      return this.info.extra || {}
    },
  },
  methods: {
    lookDetails(row: any) {
      this.hz = {...this.hz, info: row.payload, visible: true}
    },
  },
})
