/*
 * @Date         : 2022-03-18 21:42:13
 * @Author       : dwyanewang
 * @LastEditors  : dwyanewang
 * @LastEditTime : 2022-03-18 21:50:28
 * @description  :
 * @FilePath     : \rms-vue2.x-ts\src\assets\icons\index.ts
 */
import Vue from 'vue'
import SvgIcon from '@/components/SvgIcon/index.vue' // svg component

// register globally
Vue.component('svg-icon', SvgIcon)

const req = require.context('./svg', false, /\.svg$/)
const requireAll = (requireContext: any) => requireContext.keys().map(requireContext)
requireAll(req)
