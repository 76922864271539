/*
 * @Date         : 2023-02-08 16:29:41
 * @Author       : dwyanewang
 * @LastEditors  : dwyanewang
 * @LastEditTime : 2023-10-31 13:18:47
 * @description  :
 * @FilePath     : \yf-groupadmin_v2\src\utils\dw.ts
 */
import {ElForm} from 'element-ui/types/form'
import Cookies, {CookieAttributes} from 'js-cookie'
import _ from 'lodash'

// 自定义Cookie
const cookiePrefix = process.env.VUE_APP_COOKIE_PREFIX || 'dw_'

/**
 * 获取Cookie
 */
export const getCookie = (name: string | Array<string>) => {
  return _.isArray(name) ? name.map(_name => Cookies.get(cookiePrefix + _name)) : Cookies.get(cookiePrefix + name)
}

type DWCookie = {
  name: string
  value: any
  options?: CookieAttributes
}

/**
 * 设置Cookie
 */
export const setCookie = (args: Array<DWCookie> | DWCookie) => {
  if (_.isArray(args)) {
    args.forEach(({name, value, options}) => {
      Cookies.set(cookiePrefix + name, value, options)
    })
  } else {
    const {name, value, options} = args
    Cookies.set(cookiePrefix + name, value, options)
  }
}

/**
 * 删除cookie
 */
export const delCookie = (name: string | Array<string>) => {
  _.isArray(name) ? name.forEach(_name => Cookies.remove(cookiePrefix + _name)) : Cookies.remove(cookiePrefix + name)
}

/**
 * 获取页面标题
 */
export const getPageTitle = (pageTitle: string) => {
  const title: string = process.env.VUE_APP_SYS_SHORT_NAME || 'dw-admin'
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}

// 日期格式化
export function parseTime(time: string | number | Date, pattern?: string) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time
        .replace(new RegExp(/-/gm), '/')
        .replace('T', ' ')
        .replace(new RegExp(/\.[\d]{3}/gm), '')
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result: string, key: 'y' | 'm' | 'd' | 'h' | 'i' | 's' | 'a') => {
    let value = String(formatObj[key])
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][Number(value)]
    }
    if (result.length > 0 && Number(value) < 10) {
      value = '0' + value
    }
    return value || '0'
  })
  return time_str
}

export const formatImg = (url?: string) => {
  if (url === '' || url === undefined || !url.includes('/')) return ''
  const reg = /(http|https):\/\/([\w.]+\/?)\S*/g
  if (reg.test(url)) return url
  return process.env.VUE_APP_BASE_API + url
}

// 表单重置
export function resetForm(_this: Vue, refName: string, cb: () => any, resetFn: () => any) {
  ;(_this.$refs[refName] as ElForm)?.resetFields()
  typeof resetFn === 'function' && resetFn()
  typeof cb === 'function' && cb()
}

/**
 * 初始化搜索栏时间范围选择框数据
 * @param {{
 *  recent: 'day' | 'week' | 'month' | 'year'
 *  pattern: string
 *  start: string | Date
 * }} options pattern格式'{y}-{m}-{d} {h}:{i}:{s}'
 * @returns {Array}
 */
export function initTime2Search(options: {start?: string | number | Date; recent?: string; pattern?: string}) {
  const _startTime = parseTime(options?.start || new Date(), '{y}-{m}-{d}') as string
  let _endTime = ''
  if (options?.constructor === Object) {
    if (typeof options?.recent === 'string') {
      const _recent = options.recent.split(' ')
      let _num = 1
      let _type = _recent[0]
      if (_recent.length > 1 && !Number.isNaN(_recent[0]) && Number(_recent[0]) > 0) {
        _num = Number(_recent[0])
        _type = _recent[1]
      }
      switch (_type) {
        case 'day':
          {
            _endTime = parseTime(new Date().getTime() - _num * 60 * 60 * 24 * 1000, '{y}-{m}-{d}') as string
          }
          break
        case 'week':
          {
            _endTime = parseTime(new Date().getTime() - _num * 7 * 60 * 60 * 24 * 1000, '{y}-{m}-{d}') as string
          }
          break
        case 'month':
          {
            const _splitStart = _startTime.split('-')
            let year,
              month,
              day = Number(_splitStart[2])
            if ((_num % 12) - Number(_splitStart[1]) < 0) {
              month =
                Math.abs((_num % 12) - Number(_splitStart[1])) < 10
                  ? '0' + Math.abs((_num % 12) - Number(_splitStart[1]))
                  : Math.abs((_num % 12) - Number(_splitStart[1]))
              year = Number(_splitStart[0]) - Math.floor(Math.abs(_num / 12))
            } else {
              month =
                12 - Math.abs((_num % 12) - Number(_splitStart[1])) < 10
                  ? '0' + (12 - Math.abs((_num % 12) - Number(_splitStart[1])))
                  : 12 - Math.abs((_num % 12) - Number(_splitStart[1]))
              year = Number(_splitStart[0]) - Math.floor(Math.abs(_num / 12)) - 1
            }
            const preDate = new Date(year, Number(month), 0).getDate()
            if (preDate < day) {
              day = preDate
            }
            _endTime = year + '-' + month + '-' + day
          }
          break
        case 'year':
          {
            const _splitStart = _startTime.split('-')
            _endTime = Number(_splitStart[0]) - _num + '-' + _splitStart[1] + '-' + _splitStart[2]
          }
          break
        default:
          {
            _endTime = _startTime
          }
          break
      }
      return [parseTime(`${_endTime} 00:00:00`, options.pattern) as string, parseTime(`${_startTime} 23:59:59`, options.pattern) as string]
    }
    return [`${_startTime} 00:00:00`, `${_startTime} 23:59:59`]
  }
  return [`${_startTime} 00:00:00`, `${_startTime} 23:59:59`]
}

/**
 * 获取svg图标列表
 */
export const svgList = () => {
  const _list: string[] = []
  const requireContext: __WebpackModuleApi.RequireContext = require.context('@/assets/icons/svg', false, /\.svg$/)
  requireContext.keys().forEach(fileName => {
    _list.push(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
  })
  return _list
}

/**
 * 过滤对象中的空数据
 */
export const omitEmptyObj = (obj: any, needZero = false) => {
  if (needZero) return _.merge(_.pickBy(obj, _.isFinite), _.omitBy(obj, _.isEmpty))
  return _.merge(
    _.pickBy(_.pickBy(obj, _.isFinite), val => val !== 0),
    _.omitBy(obj, _.isEmpty),
  )
}
