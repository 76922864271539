import Vue from 'vue'
import Vuex, {ModuleTree} from 'vuex'
import getters from './getters'

Vue.use(Vuex)

function importAllStore<S>(): ModuleTree<S> {
  const modules: ModuleTree<S> = {}
  try {
    // 导入 @/store/modules 下文件
    const requireContext: __WebpackModuleApi.RequireContext = require.context('../store/modules', false, /\.ts$/)
    requireContext.keys().forEach(fileName => {
      if (fileName !== 'index.ts') {
        // 获取内容
        const modulesContent = requireContext(fileName)
        if (modulesContent.default) {
          // 获取 PascalCase 命名
          const modulesName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
          modules[modulesName] = modulesContent.default
        }
      }
    })
  } catch (error) {
    console.log(error)
  }
  return modules
}

export default new Vuex.Store({
  getters,
  modules: importAllStore(),
})
