
import Vue from 'vue'
export default Vue.extend({
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    equip() {
      return (f => ({
        id: f.id,
        name: f.name,
        sn: f.number,
        status: f.is_online,
        equipType: `${f.equipment_type_info?.module_info?.title ?? ' -- '}/${f.equipment_type_info?.parent_path ?? ' -- '}`,
        mac: f.mac || '--',
        ip: f.ip || '--',
        addr: f.equipment_position_info?.position_name || '--',
        model: f.model || '--',
        typeCode: f?.equipment_type_info?.ename,
        desc: f?.remarks || '--',
        building: f.equipment_position_info?.building_id || 0,
        unit: f.equipment_position_info?.building_unit_id || 0,
        floor: f.equipment_position_info?.building_floor_id || 0,
        room: f.equipment_position_info?.room_id || 0,
      }))(this.info)
    },
  },
})
