/*
 * @Date         : 2023-04-13 17:42:34
 * @Author       : dwyanewang
 * @LastEditors  : dwyanewang
 * @LastEditTime : 2023-05-24 16:55:23
 * @description  : 防抖
 * @FilePath     : \yf-groupadmin_v2\src\directive\debounce.ts
 */
import {DirectiveBinding} from 'vue'

/***
 * 防抖 单位时间只触发最后一次
 *  @param {?Number|1000} time - 间隔时间
 *  @param {Function} fn - 执行事件
 *  @param {?String|"click"} event - 事件类型 例："click"
 *  @param {Array} binding.value - [fn,event,time]
 *  例：<el-button v-debounce="[reset,`click`,300]">刷新</el-button>
 *  也可简写成：<el-button v-debounce="[reset]">刷新</el-button>
 */
export default {
  inserted(el: HTMLElement, binding: DirectiveBinding<any>) {
    const [fn, event = 'click', time = 1000] = binding.value
    let timer: number | null | undefined
    el.addEventListener(event, () => {
      const firstClick = !timer
      firstClick && fn()
      timer && window.clearTimeout(timer)
      timer = window.setTimeout(() => (timer = null), time)
    })
  },
}
