
import Vue from 'vue'
export default Vue.extend({
  props: {
    content: String,
  },
  data() {
    return {
      isShow: false,
    }
  },
})
