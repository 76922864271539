import Vue, {VueConstructor} from 'vue'
import DialogInfo from './dialog-info.vue'

//将vue组件变为构造函数
const DialogInfoConstruct = Vue.extend(DialogInfo)

class DialogInfoBox {
  options: any = {}

  instance: any = null

  constructor(options: any) {
    this.options = options || {}
    this.init()
  }
  init() {
    this.instance = new DialogInfoConstruct()
    if (this.instance !== null) {
      // 初始化组件的props参数
      Object.keys(this.instance.$props || {}).forEach(key => {
        if (key in this.options) {
          this.instance[key] = this.options[key]
        }
      })
      // 渲染挂载
      this.instance.$mount()
      document.body.appendChild(this.instance.$el)
      // 显示出来
      return this.instance.show(() => this.destroy())
    }
  }
  // 销毁
  destroy() {
    if (this.instance) {
      document.body.removeChild(this.instance.$el)
      this.instance.$destroy()
    }
    this.instance = null
  }
}

function createInstance(options: any) {
  return new DialogInfoBox(options)
}

// 设备
createInstance.equipInfo = function equipInfo(options: any) {
  const common = {type: 'equip'}
  return new DialogInfoBox({...options, ...common})
}

// 订单
createInstance.orderInfo = function orderInfo(options: any) {
  const common = {type: 'order', width: '1160px'}
  return new DialogInfoBox({...options, ...common})
}

// 退款单
createInstance.refundInfo = function refundInfo(options: any) {
  const common = {type: 'refund', width: '900px'}
  return new DialogInfoBox({...options, ...common})
}

// 账户提现记录
createInstance.accountRecords = function accountRecords(options: any) {
  const common = {type: 'account', width: '800px'}
  return new DialogInfoBox({...options, ...common})
}

createInstance.install = (Vue: VueConstructor) => {
  Vue.prototype.$yfInfo = createInstance
  Vue.prototype.$equipInfo = createInstance.equipInfo
  Vue.prototype.$orderInfo = createInstance.orderInfo
  Vue.prototype.$refundInfo = createInstance.refundInfo
  Vue.prototype.$accountRecords = createInstance.accountRecords
}

export default createInstance
