
import {accountRefundOrdersDetails, getEquipDetails, getOrder2Extend, getOrderDetails, getOrderRefundDetails, getOrderRefundList} from '@/api/common'
import Vue from 'vue'
import EquipInfo from './src/equip-info.vue'
import OrderInfo from './src/order-info.vue'
import RefundInfo from './src/refund-info.vue'
import AccountRecords from './src/account-records.vue'
export default Vue.extend({
  components: {EquipInfo, OrderInfo, RefundInfo, AccountRecords},
  props: {
    id: Number,
    type: {
      type: String,
      default: 'equip',
    },
    width: {
      type: String,
      default: '900px',
    },
  },
  data() {
    return {
      visible: false,
      info: {},
    }
  },
  computed: {
    title() {
      return {equip: '设备', order: '订单', refund: '退款单', account: '提现'}[this.type as string] + '信息'
    },
  },
  methods: {
    show() {
      try {
        this.$Loading()
        this.initInfo(this.type).then(() => (this.visible = true))
      } finally {
        this.$Loading().close()
      }
    },
    async initInfo(type: string) {
      switch (type) {
        case 'equip':
          this.info = await getEquipDetails(this.id)
          break
        case 'order':
          this.info = {
            ...(await getOrderDetails(this.id)),
            extra: await getOrder2Extend(this.id),
            firstTable: await getOrderRefundList(this.id),
          }
          break
        case 'refund':
          this.info = await getOrderRefundDetails(this.id)
          break
        case 'account':
          this.info = await accountRefundOrdersDetails(this.id)
          break
      }
    },
  },
})
