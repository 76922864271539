
import {getOrderCouponList, getOrderRefundList, getOrderRelatedBillList} from '@/api/common'
import {ElTable} from 'element-ui/types/table'
import Vue from 'vue'
import businessDetails from './business-details.vue'
export default Vue.extend({
  components: {businessDetails},
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      firstTable: [],
      secondTable: [],
      thirdTable: [],
      hz: {visible: false, info: {} as any},
      feeDetail: {show: false, id: 0, type: 0},
      ywd: false,
      ywdTit: '',
    }
  },
  computed: {
    order(): any {
      return (f => ({
        id: f.id,
        sn: f.sn,
        type: f.action_title,
        typeCode: f.action_name,
        addTime: this.$parseTime(f.add_time),
        status_text: f.status_text,
        status: f.status,
        amount: f.orders_amount,
        preferentialAmount: f.preferential_amount,
        tradePrice: f.trade_price,
        ip: f.ip,
        reduction_money: f.reduction_amount,
      }))(this.info)
    },
    pay(): any {
      return (f => ({
        status_text: f.pay_status_text,
        status: f.pay_status,
        type: f.payway_text,
        paytype: f.paytype,
        payTime: f.pay_time ? this.$parseTime(f.pay_time) : '--',
        action_name: f.action_name,
        receipt_imgs: f?.callback_json?.receipt_imgs ?? '',
        account_name_text: f?.account_name_text,
        account_title: f?.account_title,
        mch_income_type: f?.mch_income_type,
        callback_json: f?.callback_json,
        account_income_type: f?.account_income_type,
        operator_info: f?.operator_info,
        operator_id: f?.operator_id,
        account_name: f?.account_name,
      }))(this.info)
    },
    merchant(): any {
      return (f => ({
        name: f?.title || '--',
        id: f?.mch_id || '',
        company: f?.company || '--',
      }))(this.info.mch_info)
    },
    logs_json(): any[] {
      return this.info.logs_json ? this.info.logs_json.filter((ele: any) => /[\u4E00-\u9FA5\uF900-\uFA2D]{1,}/.test(ele.desc)) : []
    },
    extra(): any {
      return this.info.extra || {}
    },
  },
  created() {
    this.firstTable = Object.keys(this.info.firstTable).length ? this.info.firstTable : []
  },
  methods: {
    async changeTab(tab: any) {
      this.$nextTick(() => {
        ;(this.$refs[`${tab.name}`] as ElTable).doLayout()
      })
      try {
        this.loading = true
        switch (tab.name) {
          case 'first':
            {
              const data = await getOrderRefundList(this.order.id)
              this.firstTable = Object.keys(data).length ? data : []
            }
            break
          case 'second':
            {
              const data = await getOrderCouponList(this.order.id)
              this.secondTable = Object.keys(data).length ? data : []
            }
            break
          case 'third':
            {
              const data = await getOrderRelatedBillList(this.order.id)
              this.thirdTable = Object.keys(data).length ? data : []
            }
            break
        }
      } finally {
        this.loading = false
      }
    },
    isRefund(row: any) {
      return Number(row.receivable_money || 0) < 0
    },
    lookDetails(row: any) {
      this.hz = {...this.hz, info: row.payload, visible: true}
    },
    async goYwd() {
      if (this.pay.action_name === 'e_company_account_deduction') {
        this.feeDetail = {show: true, id: this.extra.id, type: this.extra?.meter_type}
      } else {
        this.ywdTit = this.extra.title
        this.ywd = true
      }
    },
  },
})
