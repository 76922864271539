import request from '@/utils/request'

/**
 * 合同分页列表
 */
export const pageListContract = (data: {
  page: number
  page_size: number
  contract_id?: number
  status?: number
  start_time?: string
  end_time?: string
}): Promise<{items: Array<any>; total: number; status_list: Array<any>}> => {
  return request({
    url: '/business/v1.contract/index',
    method: 'POST',
    data,
  })
}

/**
 * 根据关键字获取合同下拉列表
 */
export const dpListContract = (data: {keyword: string}) => {
  return request({
    url: '/business/v1.contract/searchContractList',
    method: 'POST',
    data,
  })
}

/**
 * 根据合同ID获取相关联的房间列表
 */
export const getRoomsByContractId = (data: {contract_id?: number; room_id?: number; page?: number; page_size?: number}) => {
  return request({
    url: '/business/v1.contract/contractRoomList',
    method: 'POST',
    data,
  })
}

/**
 * 根据合同ID获取相关联的企业列表
 */
export const getCompaniesByContractId = (data: {contract_id: number}) => {
  return request({
    url: '/business/v1.contract/companyListByContract',
    method: 'POST',
    data,
  })
}

/**
 * 根据合同ID获取合同详情
 */
export const getInfoByContractId = (data: {id: number}) => {
  return request({
    url: '/business/v1.contract/info',
    method: 'POST',
    data,
  })
}

/**
 * 获取状态正常的合同下拉列表
 */
export const dpListNormalContract = () => {
  return request({
    url: '/business/v1.contract/getUserContractList',
    method: 'POST',
  })
}
