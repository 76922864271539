import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {path: '/'}, // 必须加上，不然在404页面无法跳转至首页
  {
    path: '/login',
    component: () => import('@/views/login.vue'),
  },
  {
    path: '/404',
    component: () => import('@/views/404/index.vue'),
  },
]

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  })

const router = createRouter()

export const resetRouter = () => {
  // 用初始化的matcher替换当前router的matcher
  router.matcher = createRouter().matcher
}

export default router
