import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入第三方插件
import '@/plugins/element-ui'
import '@/plugins/vxe-table'
import JsonViewer from 'vue-json-viewer'
// 引入全局样式
import '@/assets/style/global.scss'
// 引入图标
import '@/assets/icons'
// 权限控制
import './permission'
import 'default-passive-events'

// 引入全局自定义指令
import directive from './directive'
// 引入全局自定义服务
import YFInfo from '@/components/YFInfo'
// 引入自定义全局方法
import {formatImg, resetForm, initTime2Search, parseTime, omitEmptyObj} from '@/utils/dw'
// 引入自定义全局组件
import DwDialog from '@/components/DwDialog/index.vue'
import DwFormTable from '@/components/DwFormTable/index.vue'
import DwTooltip from '@/components/DwTooltip/index.vue'
import DwImgUpload from '@/components/DwImgUpload/index.vue'
import DwMoreImgUpload from '@/components/DwMoreImgUpload/index.vue'
import YFTableImg from '@/components/YFTableImg/index.vue'
import YFUser from '@/components/YFUser/index.vue'
import YFContract from '@/components/YFContract/index.vue'
import YFEnergyEquip from '@/components/YFEnergyEquip/index.vue'
import YFRoom from '@/components/YFRoom/index.vue'
import YFSpace from '@/components/YFSpace/index.vue'
import TreeSelect from '@/components/TreeSelect/index.vue'
import YFEnergyFeeDetail from '@/components/YFEnergyFeeDetail/index.vue'

Vue.config.productionTip = false

Vue.use(directive).use(YFInfo).use(JsonViewer)
// 挂载全局方法
Vue.prototype.$formatImg = formatImg
Vue.prototype.$resetForm = resetForm
Vue.prototype.$initTime2Search = initTime2Search
Vue.prototype.$parseTime = parseTime
Vue.prototype.$omitEmptyObj = omitEmptyObj
// 挂载全局组件
Vue.component('dw-dialog', DwDialog)
Vue.component('dw-form-table', DwFormTable)
Vue.component('dw-tooltip', DwTooltip)
Vue.component('dw-img-upload', DwImgUpload)
Vue.component('dw-more-img-upload', DwMoreImgUpload)
Vue.component('yf-table-img', YFTableImg)
Vue.component('yf-user', YFUser)
Vue.component('yf-contract', YFContract)
Vue.component('yf-energy-equip', YFEnergyEquip)
Vue.component('yf-room', YFRoom)
Vue.component('yf-space', YFSpace)
Vue.component('tree-select', TreeSelect)
Vue.component('yf-energy-fee-detail', YFEnergyFeeDetail)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
