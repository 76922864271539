import request from '@/utils/request'

/**
 * 登录操作
 * @param {string} mobile 用户名
 * @param {string} pwd 密码
 */
export const login = (mobile: string, pwd: string): Promise<{token: string; expire?: number}> => {
  return request({
    url: '/auth/v1.pub/login',
    method: 'POST',
    data: {mobile, pwd},
  })
}

/**
 * 获取用户信息
 */
export const getUserInfo = (): Promise<any> => {
  return request({
    url: '/auth/v1.admin/adminInfo',
    method: 'GET',
  })
  return new Promise(resolve => {
    resolve({
      id: 1,
      name: '王维',
      avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
      phone: '13296617216',
      menus: [
        {
          name: 'Home',
          path: '/home',
          redirect: '/home/test1',
          component: 'LAYOUT',
          meta: {title: 'Home'},
          children: [
            {
              name: 'Test1',
              path: 'test1',
              component: '/home/test1.vue',
              meta: {title: '测试页面1'},
            },
            {
              name: 'Test2',
              path: 'test2',
              component: '/home/test2.vue',
              meta: {title: '测试页面2'},
            },
          ],
        },
      ],
    })
  })
}

/**
 * 获取有权限的菜单路由
 */
export const getMenuList = (): Promise<any> => {
  return request({
    url: '/auth/v1.admin/getRouteList2',
    method: 'GET',
  })
}

/**
 * 登出
 */
export const logout = () => {
  return new Promise(resolve => {
    resolve('登出成功')
  })
}

/**
 * 切换企业
 * @param {Number} id 企业ID
 * @param {Number} pid 企业父级ID
 */
export const switchCompany = (id: number, pid: number): Promise<any> => {
  return request({
    url: '/auth/v1.admin/switchCompany',
    method: 'post',
    data: {
      switch_company_id2: id,
      switch_company_pid: pid,
    },
  })
}

/**
 * 发送验证码
 * @param mobile 手机号码
 */
export const sendCode = (mobile: string) => {
  return request({
    url: '/auth/v1.sms/send',
    method: 'post',
    data: {mobile},
  })
}

/**
 * 通过验证码登录
 * @param {string} mobile 手机号码
 * @param {string} code 验证码
 */
export const loginByCode = (mobile: string, code: string): Promise<{token: string; expire?: number}> => {
  return request({
    url: '/auth/v1.pub/codeLogin',
    method: 'POST',
    data: {mobile, code},
  })
}
