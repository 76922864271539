
import {getCookie} from '@/utils/dw'
import Vue from 'vue'
export default Vue.extend({
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
    uploadUrl: {
      type: String,
      default: '/pub/v1.uploadOSS/uploadImg', // 上传的图片服务器地址
    },
    uploadName: {
      type: String,
      default: 'file',
    },
    size: {
      type: String,
      default: '148px',
    },
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      headers: {AdminToken: getCookie('token')},
    }
  },
  computed: {
    multipleUploadUrl() {
      return `${process.env.VUE_APP_BASE_API}${this.uploadUrl}`
    },
    style() {
      return `--size: ${this.size}`
    },
  },
  methods: {
    handleRemove(file: any, fileList: any[]) {
      if (file && file.status === 'success') {
        this.$emit(
          'update:fileList',
          fileList.map((e: any) => ({name: e.name, url: e.response?.data?.url ?? e.url})),
        )
        this.$emit('uploadChange')
      }
    },
    handleSuccess(res: any, file: any, fileList: any[]) {
      this.$emit(
        'update:fileList',
        fileList.map((e: any) => ({name: e.name, url: e.response?.data?.url ?? e.url})),
      )
      this.$emit('uploadChange')
      this.$Loading().close()
    },
    handlePictureCardPreview(file: {url: string}) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 上传前对文件的大小的判断
    beforeAvatarUpload({name, size}: {name: string; size: number}) {
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      const suffixList = ['jpeg', 'gif', 'png', 'bmp', 'jpg']
      if (!suffixList.includes(suffix.toLowerCase())) {
        this.$message.error('上传文件格式错误，只允许上传jpg、jpeg、gif、png、bmp格式文件！')
        return false
      }
      if (size / 1024 / 1024 > 2) {
        this.$message.error('上传文件不允许大于2M！')
        return false
      }
      this.$Loading('上传中')
    },
    handleUploadError() {
      this.$message.error('上传失败,请重新上传！')
      this.$Loading().close()
    },
  },
})
