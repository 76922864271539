
import Vue, {VNode} from 'vue'
export default Vue.extend({
  props: {
    // dialog的显示与隐藏
    visible: {
      type: Boolean,
      default: false,
    },
    // dialog的标题
    title: {
      type: String,
      default: '',
    },
    // dialog窗口的宽度
    width: {
      type: String,
      default: '500px',
    },
    // 是否显示dialog的footer
    hasFooter: {
      type: Boolean,
      default: true,
    },
    // 父组件搜索条件是否为首页
    searchFlag: {
      type: Boolean,
      default: false,
    },
    // Dialog 自身是否插入至 body 元素上。嵌套的 Dialog 必须指定该属性并赋值为 true
    appendToBody: {
      type: Boolean,
      default: false,
    },
    hasNext: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      default: true,
    },
    closeThenSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    close() {
      this.cancel()
      this.closeThenSearch && this.$emit('search', {flag: this.searchFlag})
    },
    cancel() {
      this.loading = false
      this.$emit('update:visible', false)
    },
    // 提交操作
    onSubmit() {
      ;((this.$slots.default as VNode[])[0].componentInstance as any).onSubmit(
        (obj: any) => {
          this.loading = false
          this.$emit('update:visible', false)
          this.hasSearch && this.$emit('search', {flag: this.searchFlag})
          obj !== undefined && this.$emit('nextStep', obj)
        },
        (loading: boolean) => (this.loading = loading || false),
      )
    },
  },
})
