
import Vue from 'vue'
export default Vue.extend({
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: '',
    },
    preview: {
      type: Boolean,
      default: true,
    },
    showDefImg: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: '',
    },
    fontSize: {
      type: String,
      default: '',
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    previewList() {
      return this.preview ? this.list : []
    },
  },
  methods: {
    nameFace(name: string) {
      if (!name || !(typeof name === 'string')) return '未知'
      if (name.length > 2) return name.substring(name.length - 2)
      return name
    },
  },
})
