/*
 * @Date         : 2023-02-08 16:13:42
 * @Author       : dwyanewang
 * @LastEditors  : dwyanewang
 * @LastEditTime : 2023-11-06 18:26:49
 * @description  : element-ui
 * @FilePath     : \yf-groupadmin_v2\src\plugins\element-ui.ts
 */
import Vue from 'vue'

import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Main,
  Aside,
  Header,
  Menu,
  MenuItem,
  Submenu,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Button,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Radio,
  RadioGroup,
  RadioButton,
  Upload,
  Image,
  Icon,
  Avatar,
  Message,
  Scrollbar,
  Popover,
  MessageBox,
  Tooltip,
  Table,
  Pagination,
  Loading,
  TableColumn,
  DatePicker,
  Tag,
  Dialog,
  Descriptions,
  Tabs,
  TabPane,
  DescriptionsItem,
  Autocomplete,
  Cascader,
  ButtonGroup,
  Link,
  CheckboxGroup,
  Switch,
  InputNumber,
  Checkbox,
  Statistic,
  Empty,
  TimeSelect,
  Divider,
} from 'element-ui'
import {LoadingServiceOptions} from 'element-ui/types/loading'

const loading = (options?: string | LoadingServiceOptions) => {
  const content = typeof options === 'string' ? options : undefined
  const _options = typeof options === 'object' ? options : {}
  return Loading.service({
    ..._options,
    background: 'transparent',
    text: content || _options.text || '页面加载中',
  })
}

Vue.use(Breadcrumb)
  .use(BreadcrumbItem)
  .use(Container)
  .use(Main)
  .use(Aside)
  .use(Header)
  .use(Menu)
  .use(MenuItem)
  .use(Submenu)
  .use(Dropdown)
  .use(DropdownItem)
  .use(DropdownMenu)
  .use(Button)
  .use(ButtonGroup)
  .use(Form)
  .use(FormItem)
  .use(Input)
  .use(InputNumber)
  .use(Select)
  .use(Option)
  .use(Radio)
  .use(RadioGroup)
  .use(RadioButton)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Upload)
  .use(DatePicker)
  .use(TimeSelect)
  .use(Autocomplete)
  .use(Cascader)
  .use(Switch)
  .use(Table)
  .use(TableColumn)
  .use(Pagination)
  .use(Image)
  .use(Icon)
  .use(Avatar)
  .use(Empty)
  .use(Popover)
  .use(Tooltip)
  .use(Statistic)
  .use(Tag)
  .use(Dialog)
  .use(Descriptions)
  .use(DescriptionsItem)
  .use(Tabs)
  .use(TabPane)
  .use(Link)
  .use(Divider)
  .use(Scrollbar)
  .use(Loading)
  .use(Loading.directive)

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$Loading = loading
Vue.prototype.$ELEMENT = {size: 'medium'}
