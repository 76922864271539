import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {getCookie, getPageTitle, setCookie} from '@/utils/dw'
import {RouteConfig} from 'vue-router'

NProgress.configure({showSpinner: false})

const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
  if (to.query && to.query.t) setCookie({name: 'token', value: to.query.t})
  NProgress.start()
  // 动态设置标签栏标题
  document.title = getPageTitle(to.meta!.title)
  if (getCookie('token')) {
    if (to.path === '/login') {
      next({path: '/'})
      NProgress.done()
    } else {
      const hasMenus = store.getters.menus && store.getters.menus.length > 0
      if (hasMenus) {
        // 此处判断to.path是否为'/'是为了从404页面返回首页
        to.path === '/' || (from.path === '/login' && to.path === '/404') ? next({name: store.getters.homeRoute}) : next()
      } else {
        if (await store.dispatch('login/GetUserInfo')) {
          const accessRoutes = await store.dispatch('login/GenerateRoutes', store.getters.menus)
          // 向路由表中添加路由
          accessRoutes.forEach((_route: RouteConfig) => router.addRoute(_route))
          to.path === '/' ? next({name: store.getters.homeRoute}) : next({path: to.path, replace: true})
        } else {
          store.dispatch('login/LogOut').then(() => {
            next(`/login`)
            NProgress.done()
          })
        }
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
