import request from '@/utils/request'

/**
 * 根据关键字搜索企业用户
 * @param {String} keyword 关键字
 * @param {Number} type 查询类型，1为当前公司、2为当前公司及其子、母公司下所有用户
 * @param {Number} company_id
 */

export const dpListAdminPhone = (keyword: string, type: 1 | 2 = 1, company_id?: number) => {
  return request({
    url: '/auth/v1.admin/searchCompanyUser',
    method: 'POST',
    data: {keyword, type, page_size: 30, company_id},
  })
}

/**
 * 根据关键字模糊匹配房间下拉列表
 */
export const dpListRoomByKeyword = (data: {keyword: string}) => {
  return request({
    url: '/business/v1.contract/searchRoomList',
    method: 'POST',
    data,
  })
}

/**
 * 获取模糊匹配企业用户下拉列表
 */
export const dpListCompanyAdmin = (data: {keyword: string; page: number; page_size: number}) => {
  return request({
    url: '/business/v1.contract/userSearch',
    method: 'POST',
    data,
  })
}

/**
 * 根据用户名称、手机号模糊匹配用户列表
 */
export const dpListAdminInfo = (data: {keyword: string}) => {
  return request({
    url: '/business/v1.contract/searchUserByKeyword',
    method: 'POST',
    data,
  })
}

/**
 * 模糊匹配能耗设备下拉列表
 */
export const searchEnergyEquipList = (data: {keyword: string; energy_type: string}) => {
  return request({
    url: '/energy/v1.electricmeter/searchEnergyEquipment',
    method: 'POST',
    data,
  })
}

/**
 * 获取房间下拉列表
 */
export const searchRoomList = (data: {keyword: string}) => {
  return request({
    url: '/smartpark/v1.room/searchRoomList',
    method: 'POST',
    data,
  })
}

/**
 * 模糊匹配空间下拉列表
 */
export const searchSpaceList = (data: {keyword: string}) => {
  return request({
    url: '/smartpark/v1.building/aggregateSearch',
    method: 'POST',
    data,
  })
}

/**
 * 模糊水电户号下拉列表
 */
export const getAccountList = (data: {keyword: string}) => {
  return request({
    url: '/business/v1.account_recharge_orders/searchAccountList',
    method: 'POST',
    data,
  })
}

/**
 * 部门列表
 */
export const listDepartment = ({company_id}: {company_id: string}) => {
  return request({
    url: '/smartpark/v1.department/getAllTreeFlat',
    method: 'get',
    params: {company_id},
  })
}

/**
 * 搜索公司部门员工(分页)
 */
export const searchCompanyDepartmentUser = (data: {page: number; page_size: number; company_id: number; department_id?: number; name?: string}) => {
  return request({
    url: '/user/v1.user/searchCompanyDepartmentUser',
    method: 'post',
    data,
  })
}

/**
 * 搜索公司及部门(树结构)
 */
export const searchCompanyWithDepartment = () => {
  return request({
    url: `/smartpark/v1.department/searchCompanyWithDepartment`,
    method: 'post',
  })
}

/**
 * 获取企业下拉列表（包含部门信息）
 */
export const dpListCompany = () => {
  return request({
    url: '/smartpark/v1.company/list',
    method: 'POST',
  })
}

/**
 * 根据企业ID查询企业下部门信息（树形结构）
 */
export const treeDepartment = (company_id: number) =>
  request({
    url: '/auth/v1.department/getAllTree',
    method: 'POST',
    data: {company_id},
  })

/**
 * 获取设备详情信息
 */
export const getEquipDetails = (id: number) => {
  return request({
    url: '/smartpark/v1.EquipmentDetails/details',
    method: 'POST',
    data: {id},
  })
}

/**
 * 修改登录用户密码
 */
export const udpUserPwd = (data: {oldpwd: string; pwd: string; repwd: string}) => {
  return request({
    url: '/auth/v1.admin/updPwd',
    method: 'POST',
    data,
  })
}

/**
 * 获取企业额外相关数据
 */
export const getCompanyExtraData = () =>
  request({
    url: '/smartpark/v1.company/getExtraDatas',
    method: 'POST',
  })

/**
 * 获取首页数据
 */
export const getHomeInfo = () =>
  request({
    url: '/auth/v1.company/companyIndex',
    method: 'POST',
  })

/**
 * 获取合同欠款列表
 */
export const dpListContractArrears = () =>
  request({
    url: '/auth/v1.company/companyContractArrears',
    method: 'POST',
  })

/**
 * 获取企业下拉列表
 */
export const dpListAllCompany = (keyword: string) =>
  request({
    url: '/smartpark/v1.company/getCompanyList',
    method: 'POST',
    data: {keyword},
  })

/**
 * 获取订单信息详情
 */
export const getOrderDetails = (id: number) => {
  return request({
    url: '/pay/v1.PayOrdersDetails/details',
    method: 'POST',
    data: {id},
  })
}

/**
 * 获取订单扩展信息
 */
export const getOrder2Extend = (id: number) => {
  return request({
    url: '/pay/v1.PayOrdersDetails/getBusinessOrdersInfo',
    method: 'POST',
    data: {id},
  })
}

/**
 * 获取订单的退款记录列表
 */
export const getOrderRefundList = (id: number) => {
  return request({
    url: '/pay/v1.PayOrdersDetails/getRefundOrdersList',
    method: 'POST',
    data: {id},
  })
}

/**
 * 获取单笔扣费详情
 */
export const getFeeDetail = (id: number) => {
  return request({
    url: '/business/v1.account_consume_orders/getConsumeInfo',
    method: 'post',
    data: {id},
  })
}

/**
 * 获取订单的优惠券列表
 * @param {number} id
 */
export const getOrderCouponList = (id: number) => {
  return request({
    url: '/pay/v1.PayOrdersDetails/getOrdersCouponList',
    method: 'POST',
    data: {id},
  })
}

/**
 * 获取订单下的关联账单
 */
export const getOrderRelatedBillList = (orders_id: number) => {
  return request({
    url: '/business/v1.bill/ordersCorrelationBill',
    method: 'POST',
    data: {orders_id},
  })
}

/**
 * 获取订单的退款记录列表
 */
export const getOrderRefundDetails = (id: number) => {
  return request({
    url: '/pay/v1.refund_orders/details',
    method: 'POST',
    data: {id},
  })
}

/**
 * 获取账户提现信息
 */
export const accountRefundOrdersDetails = (id: number) => {
  return request({
    url: '/pay/v1.accountRefundOrders/details',
    method: 'POST',
    data: {id},
  })
}
