import Vue from 'vue'
import debounce from './debounce'
import throttle from './throttle'

const install = function (Vue: any) {
  Vue.directive('debounce', debounce)
  Vue.directive('throttle', throttle)
}

if (window.Vue) {
  window['debounce'] = debounce
  window['throttle'] = throttle
  Vue.use(install)
}

export default install
