
import Vue from 'vue'
import {Table} from 'vxe-table'
export default Vue.extend({
  props: {
    isTree: {
      type: Boolean,
      default: false,
    },
    keyField: {
      type: String,
      default: 'id',
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      result: [] as any[],
      tempResult: [] as any[],
    }
  },
  computed: {
    treeConfig() {
      return this.isTree
        ? {
            rowField: this.keyField,
            line: true,
            iconClose: 'el-icon-circle-plus-outline',
            iconOpen: 'el-icon-remove-outline',
          }
        : {}
    },
    count() {
      return this.result.length
    },
    /**
     * 初始化时勾选的row
     */
    checkRowKeys() {
      return this.result.map(e => e[this.keyField])
    },
  },
  created() {
    this.init(this.info)
  },
  methods: {
    /**
     * 初始化右侧已选择数据及左侧列表数据
     */
    init(info: any) {
      this.result = info.item
      this.$nextTick(() => {
        ;(this.$refs.table as Table).loadData(info.list)
      })
    },
    delResult(row: any) {
      if (!row) {
        ;(this.$refs.table as Table).clearCheckboxRow()
        this.result = []
      } else {
        const _row = (this.$refs.table as Table).getRowById(row[this.keyField])
        _row && (this.$refs.table as Table).setCheckboxRow(_row, false)
        this.result = this.result.filter(e => e[this.keyField] !== row[this.keyField])
      }
      this.tempResult = JSON.parse(JSON.stringify(this.result))
    },
    checkedTreeChanged({checked, row}: {checked: boolean; row: any}) {
      const _checked = (this.$refs.table as Table).getCheckboxRecords(true)
      this.$emit('change', {checked, row, allChecked: _checked, tempResult: this.tempResult})
    },
    changeResult({result, tempResult}: {result: any[]; tempResult: any[]}) {
      this.result = result
      this.tempResult = tempResult
    },
    filterTree(list: any[]) {
      ;(this.$refs.table as Table).reloadData(list)
      this.tempResult = JSON.parse(JSON.stringify(this.result))
    },
  },
})
