import Vue from 'vue'
import XEUtils from 'xe-utils'

import {
  VXETable,
  Icon,
  Filter,
  Column,
  Table,
  Form,
  FormItem,
  Pager,
  Select,
  Option,
  Input,
  Button,
  Tooltip,
  Validator,
  RadioButton,
  RadioGroup,
  Toolbar,
  Export,
  Modal,
  Radio,
  List,
  Pulldown,
} from 'vxe-table'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'

Vue.use(Icon)
  .use(Filter)
  .use(Column)
  .use(Tooltip)
  .use(Validator)
  .use(Form)
  .use(FormItem)
  .use(Pager)
  .use(Select)
  .use(Option)
  .use(Pulldown)
  .use(Input)
  .use(Radio)
  .use(RadioGroup)
  .use(RadioButton)
  .use(Button)
  .use(Toolbar)
  .use(Export)
  .use(Modal)
  .use(Table)
  .use(List)

VXETable.setup({
  size: 'medium',
  table: {
    autoResize: true,
    border: true,
  },
  pager: {
    autoHidden: true,
    background: true,
    pagerCount: 5,
    pageSizes: [10, 20, 30, 50, 100],
    layouts: ['Total', 'Sizes', 'PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'FullJump'],
  },
  form: {
    preventSubmit: false,
    titleAlign: 'right',
    titleWidth: 'auto',
  },
  input: {
    clearable: true,
  },
  i18n: (key: any, args: any) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args),
})
