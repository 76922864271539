import axios, {AxiosRequestConfig, Method} from 'axios'
import {Message} from 'element-ui'
import nProgress from 'nprogress'
import {getCookie} from './dw'

export type Config = {
  url: string
  method?: Method
  data?: object
  params?: object
  headers?: any
  baseURL?: string
}

export type Result<T> = {
  code: number
  data: T
  msg?: string
}

const whiteUrl = ['/login']

// 是否开启取消重复请求, 默认为 true
const request = (config: Config, cancelRepeat = true): Promise<any> => {
  const service = axios.create({
    baseURL: config.baseURL || process.env.VUE_APP_BASE_API || `http://${location.host}/`,
    timeout: 5000,
    method: 'GET',
  })

  // 请求拦截器
  service.interceptors.request.use(
    config => {
      removePending(config)
      cancelRepeat && addPending(config)
      if (config.url) {
        nProgress.start()
      }
      // 自动携带token
      const token = getCookie('token') as string | undefined
      if (token) {
        // 添加jwt鉴权
        config.headers!['AdminToken'] = token
      } else {
        // token和refreshToken均不存在
        if (whiteUrl.includes(config.url as string)) return config
      }
      return config
    },
    // 发送失败
    error => Promise.reject(error),
  )
  // 响应拦截器
  service.interceptors.response.use(
    response => {
      nProgress.done()
      const {data, code, msg} = response.data as Result<any>
      switch (code) {
        case 1:
          return data
        default:
          Message.warning({
            message: msg as string,
            duration: 2 * 1000,
          })
          return Promise.reject(msg)
      }
    },
    error => {
      nProgress.done()
      const {message, name} = error
      if (name !== 'CanceledError') {
        let msg
        if (message === 'Network Error') {
          msg = '后端接口连接异常'
        } else if (message.includes('timeout')) {
          msg = '后端接口请求超时'
        } else if (message.includes('Request failed with status code')) {
          msg = `后端接口【${message.substr(message.length - 3)}】错误`
        }
        Message.error({
          message: msg || '未知错误',
          duration: 1.5 * 1000,
        })
      }
      return Promise.reject(error)
    },
  )
  return service(config)
}

const pendingMap = new Map()
/**
 * 储存每个请求的唯一cancel回调, 以此为标识
 * @param {*} config
 */
function addPending(config: AxiosRequestConfig) {
  const pendingKey = getPendingKey(config)
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken(cancel => {
      if (!pendingMap.has(pendingKey)) {
        pendingMap.set(pendingKey, cancel)
      }
    })
}

/**
 * 删除重复的请求
 * @param {*} config
 */
function removePending(config: AxiosRequestConfig) {
  const pendingKey = getPendingKey(config)
  if (pendingMap.has(pendingKey)) {
    const cancelToken = pendingMap.get(pendingKey)
    // 如你不明白此处为什么需要传递pendingKey可以看文章下方的补丁解释
    cancelToken(pendingKey)
    pendingMap.delete(pendingKey)
  }
}

/**
 * 生成唯一的每个请求的唯一key
 * @param {*} config
 * @returns
 */
function getPendingKey(config: AxiosRequestConfig) {
  const {url, method, params, data} = config
  return [url, method, JSON.stringify(params), JSON.stringify(data)].join('&')
}

export default request
