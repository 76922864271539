
import {getFeeDetail} from '@/api/common'
import Vue from 'vue'
export default Vue.extend({
  props: {
    // dialog的显示与隐藏
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      money: {isElectric: false, info: {}},
      activeTab: '0',
    }
  },
  watch: {
    show(cur) {
      if (cur) {
        this.showDetails()
      }
    },
  },
  methods: {
    close() {
      this.activeTab = '0'
      this.visible = false
      this.$emit('update:show', false)
    },
    async showDetails() {
      try {
        this.$Loading()
        this.money = {
          info: await getFeeDetail(this.id),
          isElectric: this.type === 2,
        }
        this.visible = true
      } finally {
        this.$Loading().close()
      }
    },
  },
})
